<template>
  <input class="" :placeholder="placeholder" />
</template>

<script>
import Tagify from "@yaireo/tagify/dist/tagify.min.js";
import "@yaireo/tagify/dist/tagify.css";

export default {
  name: "App",
  props: {
    placeholder: String,
    isSubmitted: Boolean,
    nullify: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      tagifySettings: {
        pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        duplicates: false,
        hooks: {
          beforeRemoveTag: (tags) => {
            this.pendingRemove = "";
            this.$emit("fetchEmail", this.selectedEmails);

            return new Promise((resolve) => {
              resolve(); // go ahead delete the tag
            });
          },
        },
        editTags: false,
      },
      suggestions: [],
      selectedEmails: [],
      tagify: null
    };
  },
  watch: {
    isSubmitted(val) {
      this.ifSubmitted = val;
      if (val) {
        this.tagify.removeAllTags();
        this.selectedEmails=[]
      }
    },
    nullify(val) { 
      if (val) { 
        this.tagify.removeAllTags();
        this.selectedEmails = []
      }
    }
  },
  mounted() {
    // simulate fetchign async data and updating the sugegstions list
    // do not create a new Array, but use the SAME ONE
    this.tagify = new Tagify(this.$el, this.tagifySettings);
    this.tagify.on("add", (e) => {
      this.selectedEmails = [];
      e.detail.tagify.value.map((el) => {
        this.selectedEmails.push(el.value);
        this.$emit("fetchEmail", this.selectedEmails);
      });
    });

    this.tagify.on("remove", (e) => {
      this.selectedEmails=[]
      this.selectedEmails=e.detail.tagify.value
      console.log(e.detail.tagify.value)
      this.$emit("fetchEmail", this.selectedEmails);
    });

    this.emitter.on('clear-tagify-emails',()=>{
      this.tagify.removeAllTags();
      this.selectedEmails = []
      this.tagify.settings.whitelist.length = 0;
    })
  },
};
</script>

<style lang="scss"></style>
